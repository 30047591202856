import React, { useMemo } from 'react'
import { graphql, PageProps } from 'gatsby'
import { MainLayout } from '@tmp'
import { GetErrorPageQuery } from '@gql-types'
import { withLanguageProvider } from '../context'
import { LocalizedRoute } from '../../utils/routes'
import { PageContent } from '@src/data/mappings/pageMappings'
import Locale from '../../utils/locale'
import { assertDefined } from '@src/utils/helpers'
import ErrorPage from '@src/components/BookMeetingSteps/Steps/ErrorPage'

const PageTemplate = ({ data: { localizedPages }, location }: PageProps<GetErrorPageQuery>) => {

  const localizedUrls: Array<LocalizedRoute> = useMemo(() => {
    return localizedPages.nodes.map(({ node_locale, slug, parentPage }) => ({
      locale: node_locale as Locale,
      route: { type: 'page', slug: slug ?? '', parentSlug: parentPage?.slug ?? undefined },
    }))
  }, [localizedPages])

  return (
    <MainLayout
      seo={undefined}
      defaultSeoOverwrites={{ }}
      localizedUrls={localizedUrls}
      footerPoly={false}
    >
      <ErrorPage /> 
    </MainLayout>
  )
}

export default withLanguageProvider(PageTemplate)

export const pageQuery = graphql`
  query getErrorPage($contentfulId: String) {

    localizedPages: allContentfulPage(filter: { contentful_id: { eq: $contentfulId } }) {
      nodes {
        node_locale
        slug
        parentPage {
          slug
        }
      }
    }
  }
`
