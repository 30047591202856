import React, { useEffect, useState } from 'react'
import Text from '@cmp/Text'
import Button from '@src/components/Button'
import cn from 'classnames'
import { useStore } from '../Step-store'
import { navigate } from 'gatsby'
import { useLanguage } from '@src/templates'
import { MEETING_MAX } from '@src/utils/contants'

export const ErrorPage = () => {

  const {
    eventCode,
    numGuests,
    setCurrentStep,
  } = useStore()

  const isLargeEvent = numGuests >= MEETING_MAX

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const { t, locale } = useLanguage()

  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div
      className='flex flex-col lg:flex-row border-t'
      style={{
        minHeight: 'calc(100vh - 82px)',
      }}
    >
      <div 
        className='absolute inset-0 bg-h-full bg-no-repeat bg-quasi-alt-3 z-[-1] top-[82px] opacity-40'
        style={{
          backgroundPositionX: '-400px',
        }}
      >
      </div>
      <div className='w-full pt-[10rem] sm:pt-[16rem] gutter text-center flex flex-col'>
        <Text as='h1' weight='heavy' className='text-[3rem] leading-[50px]'>
          {t('something-went-wrong')}
        </Text>
        <div className='w-full flex sm:justify-around'>
        <Text as='p' variant='base' className='mt-5 w-full sm:w-[60%]'>
            {t('something-went-wrong-text')}
          </Text>
        </div>
        <div className='flex justify-around'>
          <Button
            onClick={async () => {
              setLoading(true)
              // Here I want to redirect to the payment page
              if (isLargeEvent) {
                locale === 'is' ? navigate('/fundarherbergi?step=3') : navigate('/en/meeting-room?step=3')
                setCurrentStep(3)
              } else if (eventCode > 0) {
                locale === 'is' ? navigate('/fundarherbergi?step=4') : navigate('/en/meeting-room?step=4')
                setCurrentStep(4)
              } else {
                locale === 'is' ? navigate('/fundarherbergi') : navigate('/en/meeting-room')
                setCurrentStep(0)
              }

              setLoading(false)
            }}
            variant='yellow'
            className={cn('min-w-[280px] sm:min-w-[40%] mb-12 max-h-[56px] mt-5', { 'opacity-50': isLoading })}
            icon
          >
            {t('try-again')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ErrorPage
